import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import FundingRate from '@/views/FundingRate.vue';
import Settings from '@/views/FundingRateSettings.vue'
import TradingVolumeSettings from '@/views/TradingVolumeSettings.vue';
// import FundingRateSettings from '@/views/FundingRateSettings.vue';
import BTCTraderPriceSetting from '@/views/BTCTraderPriceSetting.vue'
import Index from '@/views/index/IndexPage.vue'
import MyWallet from '@/views/MyWallet.vue'
import TwitterMonitor from '@/views/TwitterMonitor.vue'
import BTCSpotMarketMaker from '@/views/marketmaker/spot/BTCSpotMarketMaker.vue'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
      meta: { title: '首页' }
    },
    {
      path: '/btc_spot_trader',
      name: 'BTCSpotMarketMaker',
      component: BTCSpotMarketMaker,
      meta: { title: 'BTC现货做市' },
      children: [
        {
          path: '',
          component: ()=> import('@/components/marketmaker/spot/BTCSpotMarketMakerResult.vue')
        },
        {
          path: 'settings',
          component: ()=> import('@/components/marketmaker/spot/BTCSpotMarketMakerSettings.vue')
        },
        {
          path: 'result',
          component: ()=> import('@/components/marketmaker/spot/BTCSpotMarketMakerResult.vue')
        },
        {
          path: 'log',
          component: ()=> import('@/components/marketmaker/spot/BTCSpotMarketMakerLog.vue')
        }
      ]
    },
    {
      path: '/funding_rate',
      name: 'FundingRate',
      component: FundingRate,
      meta: { title: '币安合约资金费率' }
    },
    {
      path: '/funding_rate_settings',
      name: 'funding_rate_Settings',
      component: Settings, 
      meta: { title: '设置' }
    },
    {
      path: '/trading_volume_settings',
      name: 'TradingVolumeSettings',
      component: TradingVolumeSettings,
      meta: { title: '自动交易设置' }
    },
    {
      path: '/btc_price_setting',
      name: 'BTCTraderPriceSetting',
      component: BTCTraderPriceSetting,
      meta: { title: '价格设置' }
    },
    {
      path: '/wallet',
      name: 'MyWallet',
      component: MyWallet,
      meta: { title: '我的钱包' }
    },
    {
      path: '/twitter',
      name: 'TwitterMonitor',
      component: TwitterMonitor,
      meta: { title: '推特监控' }
    },
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title'; // 设置页面标题
  const menuStatus = to.path != '/' && to.path != '/index' && to.path != '/login';
  store.commit('setShowMenu', menuStatus);
  next();
});

export default router;