<template>
    <div class="no-select links">
        <h1>我的工具</h1>
        <el-card class="card" v-for="link in my_links" :key="link.name" style="width: 480px" shadow="hover"
            @click="goMyLink(link.url)">
            {{ link.name }}
        </el-card>
    </div>
    <div class="no-select links">
        <h1>交易</h1>
        <el-tooltip class="box-item" v-for="link in exchanges" :key="link.name" effect="dark" :content="link.desc"
            placement="top">
            <el-card class="card" style="width: 480px" shadow="hover" @click="goToLink(link.url)">
                {{ link.name }}
            </el-card>
        </el-tooltip>
    </div>
    <div class="no-select links">
        <h1>资讯</h1>
        <el-card class="card" v-for="link in news" :key="link.name" style="width: 480px" shadow="hover"
            @click="goToLink(link.url)">
            {{ link.name }}
        </el-card>
    </div>
    <div class="no-select links">
        <el-tooltip class="box-item" v-for="link in links" :key="link.name" effect="dark" :content="link.des"
            placement="top">
            <el-card class="card" style="width: 480px" shadow="hover" @click="goToLink(link.url)">
                {{ link.name }}
            </el-card>
        </el-tooltip>

    </div>
</template>

<script>
export default {
    name: 'CryptoLinks',
    data() {
        return {
            my_links: [
                { name: '币安合约资金费率套现', url: '/funding_rate' },
                { name: '币安现货BTCFDUSD做市', url: '/btc_spot_trader' },
                { name: '币安合约BTCUSDT做市', url: '/btc_price_setting' },
                { name: '推特监控', url: '/twitter' },
                { name: '个人钱包', url: '/wallet' },
            ],
            exchanges: [
                { name: 'Binance', url: 'https://www.binance.com', desc: 'Binance（币安）是全球领先的加密货币交易平台之一，由赵长鹏（CZ）于2017年创立。它提供广泛的加密货币交易服务，包括现货交易、杠杆交易、合约交易、质押、借贷以及Launchpad（新币发行平台）等。' },
                { name: 'Coinbase', url: 'https://www.coinbase.com', desc: 'Coinbase 是一家美国的数字货币交易平台，由布赖恩·阿姆斯特朗（Brian Armstrong）和弗雷德·埃尔萨姆（Fred Ehrsam）于2012年创立。它提供了一个安全、受监管的平台，让用户可以购买、销售、兑换和存储各种加密货币' },
                { name: 'OKX', url: 'https://www.okx.com', desc: 'OKX（原名 OKEx）是一个全球性的加密货币交易平台，提供多种数字资产的交易服务，包括现货交易、期货合约、杠杆交易、期权合约以及各种创新交易产品。' },
                { name: 'Kraken', url: 'https://www.kraken.com', desc: 'Kraken 是一家成立于2011年的加密货币交易平台，它以提供安全、合规的交易服务而闻名。' },
                { name: 'Bybit', url: 'https://www.bybit.com', desc: 'Bybit 是一家全球性的加密货币衍生品交易平台，主要提供比特币、以太坊等加密货币的永续合约交易服务。' },
                { name: 'Kucoin', url: 'https://www.kucoin.com', desc: 'KuCoin（库币网）是一家全球性的加密货币交易平台，它提供多种数字资产的交易服务，包括比特币、以太坊等主流加密货币以及各种代币和ICO项目。' },
                { name: 'Bitfinex', url: 'https://www.bitfinex.com', desc: 'Bitfinex 是一家全球性的加密货币交易平台，它提供多种数字资产的交易服务，包括比特币、以太坊、莱特币等主流货币以及多种其他加密货币。' },
                { name: 'Bitstamp', url: 'https://www.bitstamp.net', desc: 'Bitstamp 是一家成立于2011年的加密货币交易平台，总部位于卢森堡。它提供比特币、以太坊、莱特币等多种加密货币的买卖服务，以及美元、欧元等法定货币的交易对。' },
                { name: 'Huobi', url: 'https://www.huobi.com', desc: '火币全球站（Huobi Global）是全球领先的数字资产交易平台之一，提供比特币、以太坊等多种数字货币的交易服务。' },
                { name: 'Gate', url: 'https://www.gate.io', desc: 'Gate.io 是一家全球性的数字资产交易平台，提供多种加密货币的交易服务。' },
                { name: 'Crypto', url: 'https://crypto.com', desc: 'Crypto.com 是一个提供多种加密货币相关服务的平台，包括但不限于加密货币交易、钱包、支付解决方案、以及加密货币借记卡。它允许用户购买、销售、存储加密货币，同时也提供一些金融服务，比如赚取利息、贷款和质押加密货币。Crypto.com 还致力于推动加密货币的普及和使用，通过提供易于使用的应用程序和网站界面，以及与体育和娱乐产业的合作，来增加其品牌知名度和市场影响力。' },
            ],
            news: [
                { name: 'CoinDesk', url: 'https://www.coindesk.com/' },
                { name: 'CoinTelegraph', url: 'https://cointelegraph.com/' },
                { name: 'CryptoSlate', url: 'https://cryptoslate.com/' },
                { name: 'CoinGecko', url: 'https://www.coingecko.com/' },
                { name: 'CoinMarketCap', url: 'https://coinmarketcap.com/' },
                { name: 'CryptoCompare', url: 'https://www.cryptocompare.com/' },
                { name: 'NewsBTC', url: 'https://www.newsbtc.com/' },
                { name: 'The Block', url: 'https://www.theblockcrypto.com/' },
                { name: 'BeInCrypto', url: 'https://www.beincrypto.com/' },
                { name: 'Decrypt', url: 'https://decrypt.co/' },
                { name: 'Bitcoin Magazine', url: 'https://bitcoinmagazine.com/' },
                { name: 'CoinPedia', url: 'https://www.coinpedia.org/' },
                { name: 'Crypto News', url: 'https://www.crypto-news.net/' },
                { name: 'TrustNodes', url: 'https://trustnodes.com/' },
                { name: 'CoinCheckup', url: 'https://coincheckup.com/' },
            ],
            links: [
                { name: 'CoinMarketCap', url: 'https://coinmarketcap.com' },
                { name: 'CoinGecko', url: 'https://coingecko.com' },
                { name: 'Binance', url: 'https://binance.com' },
                { name: 'Bitfinex', url: 'https://bitfinex.com' },
            ],
        };
    },
    methods: {
        goMyLink(url) {
            this.$router.push(url);
        },
        goToLink(url) {
            window.open(url, '_blank');
        }
    }
};
</script>

<style scoped>
.no-select {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE/Edge */
    user-select: none;
    /* 标准语法 */
}

h1 {
    width: 100%;
    font-family: '黑体';
    color: #4b4545;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 20px;
    align-content: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.card {
    flex: 0 0 auto;
    width: calc((100% / 5) - 20px);
    text-align: center;
    margin-right: 10px;
    margin-bottom: 20px;
    /* padding: 10px; */
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    background-color: #b5cea8;
}
</style>
