<template>
    <el-container class="settings">
        <el-header>
            <h2>合约自动开仓设置</h2>
        </el-header>
        <el-main>
            <el-form :model="settings" label-width="120px">
                <el-form-item label="API Key">
                    <el-input v-model="settings.apiKey" />
                </el-form-item>
                <el-form-item label="API Secret">
                    <el-input v-model="settings.apiSecret" show-password />
                </el-form-item>
                <el-form-item label="开仓倍数">
                    <el-input-number v-model="settings.leverage" :min="1" />
                </el-form-item>
                <el-form-item label="仓位模式">
                    <el-select v-model="settings.positionMode" placeholder="选择模式">
                        <el-option label="逐仓" value="isolated" />
                        <el-option label="全仓" value="cross" />
                    </el-select>
                </el-form-item>
                <el-form-item label="开仓方式">
                    <el-select v-model="settings.orderType" placeholder="选择方式">
                        <el-option label="Market" value="market" />
                        <el-option label="Limit" value="limit" />
                        <el-option label="Order Book Position" value="orderBook" />
                    </el-select>
                </el-form-item>
                <el-form-item v-if="settings.orderType === 'orderBook'" label="订单簿档位">
                    <el-input-number v-model="settings.orderBookPosition" :min="1" />
                </el-form-item>
                <el-form-item label="自动开仓时间">
                    <el-time-picker v-model="settings.autoBuyTime" placeholder="选择时间" />
                </el-form-item>
                <el-form-item label="自动卖出时间">
                    <el-time-picker v-model="settings.autoSellTime" placeholder="选择时间" />
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="settings.autoTrade">开启自动买入卖出</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveSettings">保存设置</el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            settings: {
                apiKey: '',
                apiSecret: '',
                leverage: 1,
                positionMode: 'isolated',
                orderType: 'market',
                orderBookPosition: 1,
                autoBuyTime: '',
                autoSellTime: '',
                autoTrade: false
            }
        };
    },
    methods: {
        saveSettings() {
            // Save settings to local storage or send to server
            localStorage.setItem('tradeSettings', JSON.stringify(this.settings));
            this.$message({
                message: '设置已保存',
                type: 'success'
            });
            // You can also send these settings to a server if needed
        }
    },
    mounted() {
        // Load settings from local storage if available
        const savedSettings = localStorage.getItem('tradeSettings');
        if (savedSettings) {
            this.settings = JSON.parse(savedSettings);
        }
    }
};
</script>

<style scoped>
.settings {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
}

.el-header {
    text-align: center;
    padding: 20px 0;
}

.el-main {
    padding: 20px;
}

.el-form-item {
    margin-bottom: 20px;
}

.el-button {
    width: 100%;
}
</style>
