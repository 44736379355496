<template>
  <el-container class="index-container">
    <el-dialog v-model="centerDialogVisible" title="友情提示" width="20vw" destroy-on-close center>
      <div>
        <strong></strong>如果网页内容显示异常，请打开VPN后重试<strong></strong>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-header>
      <h1>Great Bear Quant</h1>
    </el-header>
    <el-main class="index-main">
      <router-view></router-view>
    </el-main>
    <el-footer>
      <p>© 2024 Great Bear Quant</p>
    </el-footer>
  </el-container>
</template>

<script setup>
import { ref } from 'vue';
import { ElDialog } from 'element-plus';

const centerDialogVisible = ref(false)


</script>
<style scoped>
.index-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.index-main {
  flex-grow: 1;
  padding: 0 !important;
}
</style>
<style>
/* 全局样式或特定组件的样式中 */
html,
body,
#app {
  height: 100%;
  margin: 0;
}

.el-header {
  display: flex;
  height: 50px;
  align-items: center;
  color: #fff;
  background-color: #353739;
  text-align: left;
}

.el-footer {
  height: 50px;
  background-color: #353739;
  text-align: center;
  color: #fff;
  padding: 10px;
}
</style>
