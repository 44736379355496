import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

import ElementPlus from 'element-plus';
import ElementUI from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/index.css';



const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus);
app.use(ElementUI)
// app.config.globalProperties.$message = ElementPlus.Message;

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')

// createApp(App).mount('#app')


