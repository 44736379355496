<template>
    <crypto-prices></crypto-prices>
    <CryptoLinks></CryptoLinks>
</template>

<script setup>
import { onMounted } from 'vue'

import { useRouter } from 'vue-router';

import CryptoPrices from '@/components/index/CryptoPrice.vue';
import CryptoLinks from '@/components/index/CryptoLinks.vue';

const router = useRouter();

onMounted(() => {
    setTimeout(() => {
        router.push('/btc_spot_trader');
    }, 3000);
});

</script>

<style  scoped></style>