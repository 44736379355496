<template>
    <h2>币安现货交易设置</h2>
    <el-row :gutter="20">
        <el-col :span="8" :offset="8">
            <el-form :rules="rules" label-width="30%">
                <el-card shadow="hover">
                    <el-form-item label="API KEY" prop="apiKey">
                        <el-input placeholder="请输入API KEY" v-model="apiKey"></el-input>
                    </el-form-item>
                    <el-form-item label="API Secret" prop="apiSecret">
                        <el-input placeholder="请输入API Secret" v-model="apiSecret" show-password></el-input>
                    </el-form-item>
                </el-card>
                <el-card shadow="hover">
                    <el-form-item label="机器人运行状态" prop="running">
                        <el-switch v-model="running" active-text="运行" inactive-text="停止"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"></el-switch>
                    </el-form-item>
                </el-card>
                <el-card shadow="hover">
                    <el-form-item label="币种" prop="coin">
                        <el-select v-model="coin" placeholder="请选择币种" @change="handleCoinChange">
                            <el-option v-for="item in coinOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="当前价格" prop="price" href="https://www.binance.com/zh-CN/trade/{{coin}}"
                        target="_blank" v-if="coin">
                        <el-link type="warning">{{ price }}</el-link>
                    </el-form-item> -->
                    <!-- <el-form-item label="运行价格">
                        <el-slider v-model="priceRange" range :min="price * 0.5" :max="price * 1.5" :show-input="true"
                            disabled :format-tooltip="formatTooltip"></el-slider>
                    </el-form-item> -->
                    <el-form-item label="运行价格区间">
                        <el-col :span="11">
                            <el-tooltip content="低于该价格不运行，默认为0，不限制" placement="top">
                                <el-input placeholder="最低价格 - 0" :min="0" v-model="minPrice" />
                            </el-tooltip>
                        </el-col>
                        <el-col class="line" :span="2"> - </el-col>
                        <el-col :span="11">
                            <el-tooltip content="高于该价格不运行，默认为0，不限制" placement="top">
                                <el-input placeholder="最高价格 - 0" :min="0" :precision="2" v-model="maxPrice" />
                            </el-tooltip>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="买入订单类型" prop="buyOderType">
                        <el-radio-group v-model="buyOrderType">
                            <el-radio-button v-model="buyOrderType" label="market">市价买入</el-radio-button>
                            <el-radio-button v-model="buyOrderType" label="limit">限价买入</el-radio-button>
                            <el-radio-button v-model="buyOrderType" label="bbo" disabled>BBO</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="买入价格差" prop="buyPriceMargin" v-if="buyOrderType == 'limit'">
                        <el-input v-model="buyPriceMargin" placeholder="当前价格减去价格差，默认0" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="买入订单超时时间" prop="buyOrderTimeout" v-if="buyOrderType == 'limit'">
                        <el-input v-model="buyOrderTimeout" placeholder="默认30秒" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="卖出订单类型" prop="sellOrderType">
                        <el-radio-group v-model="sellOrderType">
                            <el-radio-button v-model="sellOrderType" label="market">市价卖出</el-radio-button>
                            <el-radio-button v-model="sellOrderType" label="limit">限价卖出</el-radio-button>
                            <el-radio-button v-model="buyOrderType" label="bbo" disabled>BBO</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="卖出价格差" prop="sellPriceMargin" v-if="sellOrderType == 'limit'">
                        <el-tooltip content="当前价格加上价格差，默认0" placement="top">
                            <el-input v-model="sellPriceMargin" type="number"></el-input></el-tooltip>
                    </el-form-item>
                    <el-form-item label="订单金额" prop="orderAmount">
                        <el-input-number v-model="orderAmount" :min="10" :step="2"></el-input-number>
                    </el-form-item>
                    <el-form-item label="订单间隔时间" prop="orderInterval">
                        <el-input-number v-model="orderInterval" :precision="2" :step="0.1" :min="0.1"
                            :max="100"></el-input-number>
                    </el-form-item>



                </el-card>
                <el-form-item>
                    <el-button type="primary" @click="saveConfig">保存配置</el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus'
import axios from 'axios';



const running = ref(true);
const apiKey = ref('')
const apiSecret = ref('')
const coin = ref('BTCFDUSD')
const price = ref(0)
const orderAmount = ref(10)
const orderInterval = ref(0.1)

const buyOrderType = ref('market')
const sellOrderType = ref('market')

const buyPriceMargin = ref(0)
const sellPriceMargin = ref(0)

const buyOrderTimeout = ref(30)

const minPrice = ref(0)
const maxPrice = ref(0)


let ws;




const subscribe = (coin) => {
    const wsUrl = 'wss://stream.binance.com/ws';
    ws = new WebSocket(wsUrl);

    // 处理 WebSocket 连接打开事件
    ws.onopen = () => {
        console.log('WebSocket 连接已打开');
        // 发送订阅消息
        ws.send(JSON.stringify({ "method": "SUBSCRIBE", "params": [`${coin.toLowerCase()}@miniTicker`], "id": 1 }));
    };

    ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.e === '24hrMiniTicker') {
            price.value = message.c;
            console.log(message)
        }
    };

    ws.onclose = () => {
        console.log('WebSocket 连接已关闭');
    };

    ws.onerror = (err) => {
        console.error('WebSocket 连接发生错误:', err);
    };
}

const unsubscribe = () => {
    if (ws) {
        ws.close();
    }
}

const handleCoinChange = () => {
    unsubscribe()
    subscribe(coin.value)
}

onMounted(() => {
    // subscribe(coin.value);
})



const rules = {
    coin: [{ required: true, message: '请选择币种', trigger: 'blur' }],
    priceRange: [{ required: true, message: '请输入价格区间', trigger: 'blur' }],
    orderAmount: [{ required: true, message: '请输入订单金额', trigger: 'blur' }],
    orderInterval: [{ required: true, message: '请输入订单间隔时间', trigger: 'blur' }]
};

const coinOptions = [
    { value: 'BTCFDUSD', label: 'BTCFDUSD' },
    { value: 'BTCUSDT', label: 'BTCUSDT' },
    { value: 'ETHUSDT', label: 'ETHUSDT' },
];

// const formatTooltip = val => {
//     return `${val}`;
// };

const saveConfig = () => {
    const trading_settings = ref({
        'phone': '13821610822',
        'running': running,
        'api_key': apiKey,
        'api_secret': apiSecret,
        'buy_order_type': buyOrderType,
        'sell_order_type': sellOrderType,
        'buy_order_min_timeout': 10,
        'buy_order_max_timeout': 20,
        'buy_order_timeout': buyOrderTimeout,
        'buy_price_margin': buyPriceMargin,
        'sell_price_margin': sellPriceMargin,
        'min_price': minPrice,
        'max_price': maxPrice,
        'order_amount': orderAmount,
        'buy_order_interval': orderInterval,
        'hold_amount': 25
    })
    const trading_settings_url = 'https://quant.greatbear.site/trading_volume_settings'
    axios.post(trading_settings_url, trading_settings.value)
        .then(response => {
            ElMessage({
                showClose: true,
                message: response.data,
                type: 'success'
            });
        })
        .catch(error => {
            console.log(error)
            ElMessage({
                showClose: true,
                message: '保存失败：' + error.message,
                type: 'error'
            });
        })

}

</script>

<style scoped></style>
