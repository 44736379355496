<template>
  <div class="container">
    <h2>币安合约资金费率实时排行</h2>
    <div class="columns">
      <div class="column">
        <h3>资金费率从高到低</h3>
        <table class="pairs-table">
          <thead>
            <tr>
              <th>交易对</th>
              <th>资金费率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pair in sortedPairsDescending" :key="pair.symbol" class="pair-item"
              @click="openContract(pair.symbol)">
              <td class="pair-symbol">{{ pair.symbol }}</td>
              <td class="pair-funding-rate">
                <span :class="{ 'positive-rate': pair.fundingRate >= 0, 'negative-rate': pair.fundingRate < 0 }">{{
                  pair.fundingRate }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <button v-if="sortedPairsDescending.length > visibleCount" @click="loadMoreDescending">查看更多</button> -->
      </div>
      <div class="column">
        <h3>资金费率从低到高</h3>
        <table class="pairs-table">
          <thead>
            <tr>
              <th>交易对</th>
              <th>资金费率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pair in sortedPairsAscending" :key="pair.symbol" class="pair-item"
              @click="openContract(pair.symbol)">
              <td class="pair-symbol">{{ pair.symbol }}</td>
              <td class="pair-funding-rate">
                <span :class="{ 'positive-rate': pair.fundingRate >= 0, 'negative-rate': pair.fundingRate < 0 }">{{
                  pair.fundingRate }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div>
          <button v-if="sortedPairsAscending.length != visibleCount" @click="loadAllAscending">查看全部</button>
          <button v-if="sortedPairsAscending.length > visibleCount" @click="loadMoreAscending">查看更多</button>
          <button v-if="initCount < visibleCount" @click="loadLessAscending">收起</button>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pairs: [], // 存储所有交易对资金费率数据
      initCount: 10,
      visibleCount: 10
    };
  },
  computed: {
    sortedPairsDescending() {
      // 按资金费率降序排序
      return [...this.pairs].sort((a, b) => b.fundingRate - a.fundingRate);
    },
    sortedPairsAscending() {
      // 按资金费率升序排序
      return [...this.pairs].sort((a, b) => a.fundingRate - b.fundingRate);
    },
    visiblePairsDescending() {
      // 返回前 visibleCount 个交易对
      return this.sortedPairsDescending.slice(0, this.visibleCount);
    },
    visiblePairsAscending() {
      // 返回前 visibleCount 个交易对
      return this.sortedPairsAscending.slice(0, this.visibleCount);
    }
  },
  mounted() {
    const ws = new WebSocket('wss://fstream.binance.com/ws/!markPrice@arr@1s');

    ws.addEventListener('ping', (event) => {
      console.log('Received a ping:', event.data);

      // 手动发送 pong 响应，这通常是自动完成的
      ws.pong(event.data);
    });

    ws.onmessage = event => {
      if (event.type === 'ping') {
        console.log(event.type)
        console.log(event.data)
        ws.pong(event.data);
      }
      const data = JSON.parse(event.data);

      // 解析收到的数据并更新 pairs 数组
      this.pairs = data.map(item => ({ symbol: item.s, fundingRate: parseFloat(item.r) }));
    };

    ws.onerror = error => {
      console.error('WebSocket error:', error);
    };
  },
  methods: {
    loadMoreDescending() {
      this.visibleCount += this.initCount;
    },
    loadMoreAscending() {
      this.visibleCount += this.initCount;
    },
    loadLessAscending() {
      if (this.initCount < this.visibleCount) {
        this.visibleCount = this.initCount;
      }
    },
    loadAllAscending() {
      this.visibleCount = this.pairs.length
    },
    openContract(symbol) {
      const url = `https://www.binance.com/zh-CN/futures/${symbol}`;
      window.open(url, '_blank');
    },
  }
};
</script>

<style>
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  margin: 20px;
  min-width: 280px;
}

.pairs-table {
  width: 100%;
  border-collapse: collapse;
}

.pairs-table th,
.pairs-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.pairs-table th {
  background-color: #f4f4f4;
  text-align: center;
  /* 表头居中 */
}

.pair-item {
  cursor: pointer;
}

.pair-item:hover {
  background-color: #f1f1f1;
}

.pair-item:nth-child(even) {
  background-color: #f9f9f9;
}

.pair-symbol {
  font-weight: bold;
  color: #333;
}

.pair-funding-rate {
  font-size: 14px;
}

.positive-rate {
  color: green;
}

.negative-rate {
  color: red;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

h3 {
  text-align: center;
  margin-bottom: 10px;
}

button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
}

@media (max-width: 480px) {

  .pairs-table th,
  .pairs-table td {
    padding: 6px;
  }

  .pair-funding-rate {
    font-size: 12px;
  }
}
</style>
