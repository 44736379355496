<template>
    <h2>交易设置</h2>
    <div class="price">
        <div :class="connectionStatusClass">
            {{ connectionStatus }}
        </div>
        <el-form-item label="交易状态">
            <el-switch v-model="trader_status" class="ml-2" inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="运行" inactive-text="停止"
                @change="setTraderStatus" />
        </el-form-item>
        <el-form-item label="订单取消状态">
            <el-switch v-model="canceler_status" class="ml-2" inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="运行" inactive-text="停止"
                @change="setTraderStatus" />
        </el-form-item>
        <el-form-item label="买入状态">
            <el-switch v-model="buyer_status" class="ml-2" inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="运行" inactive-text="停止"
                @change="setBuyerStatus" />
        </el-form-item>
        <el-form-item label="卖出状态">
            <el-switch v-model="seller_status" class="ml-2" inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="运行" inactive-text="停止"
                @change="setSellerStatus" />
        </el-form-item>

        <el-form-item label="滑块最小值">
            <el-input-number v-model="minPrice" />
        </el-form-item>
        <el-form-item label="滑块最大值">
            <el-input-number v-model="maxPrice" />
        </el-form-item>
        <el-form-item label="买入最高价格">
            <el-slider v-model="sliderValue" :min="minPrice" :max="maxPrice" :step="1" @change="onSliderInput" show-input
                v-if="sliderInitialized"></el-slider>
        </el-form-item>

    </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
    data() {
        return {
            canceler_status: true,
            trader_status: true,
            seller_status: true,
            buyer_status: true,
            maxPrice: 72000,
            minPrice: 68000,
            // sliderValue: (this.maxPrice + this.minPrice) / 2,
            sliderValue: 68111,
            sliderInitialized: true,
            websocket: null,
            isConnected: false,
        };
    },
    computed: {
        connectionStatusClass() {
            return {
                'connected': this.isConnected,
                'disconnected': !this.isConnected
            };
        },
        connectionStatus() {
            if (!this.isConnected) {
                return '已断开连接，请尝试刷新页面'
            }
            if (this.isConnected) {
                return '已成功连接'
            }
            return '未连接'
        }
    },
    created() {
        // this.detectMobile();
        this.initWebSocket();
    },
    methods: {
        initWebSocket() {
            // 替换为你的 WebSocket 服务器地址
            this.websocket = new WebSocket('wss://sg.greatbear.site:9443/ws');
            // this.websocket = new WebSocket('ws://localhost:9443/ws');

            this.websocket.onopen = () => {
                console.log('WebSocket connection opened');
                // 请求初始化值
                this.isConnected = true;
                this.websocket.send(JSON.stringify({ method: 'get_price' }));
            };

            this.websocket.onclose = () => {
                this.isConnected = false;
            };

            this.websocket.onerror = (error) => {
                console.error('WebSocket error:', error);
                this.isConnected = false;
            };

            this.websocket.onmessage = (message) => {
                console.log(message)
                const data = JSON.parse(message.data);

                this.sliderValue = data.max_price;
                this.maxPrice = this.sliderValue + 1000
                this.minPrice = this.sliderValue - 1000
                this.sliderInitialized = true;
            };

        },
        setTraderStatus(value) {
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                const send_data = {
                    'method': 'set_price',
                    'params': {
                        'trader_status': value
                    }
                }
                this.websocket.send(JSON.stringify(send_data));
            } else {
                ElMessage({
                    showClose: true,
                    message: '链接已断开，请刷新页面后重试',
                    type: 'error'
                });
            }
        },
        setBuyerStatus(value) {
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                const send_data = {
                    'method': 'set_price',
                    'params': {
                        'buyer_status': value
                    }
                }
                this.websocket.send(JSON.stringify(send_data));
            } else {
                ElMessage({
                    showClose: true,
                    message: '链接已断开，请刷新页面后重试',
                    type: 'error'
                });
            }
        },
        setSellerStatus(value) {
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                const send_data = {
                    'method': 'set_price',
                    'params': {
                        'seller_status': value
                    }
                }
                this.websocket.send(JSON.stringify(send_data));
            } else {
                ElMessage({
                    showClose: true,
                    message: '链接已断开，请刷新页面后重试',
                    type: 'error'
                });
            }
        },
        onSliderInput(value) {
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                const send_data = {
                    'method': 'set_price',
                    'params': {
                        'max_price': value
                    }
                }
                this.websocket.send(JSON.stringify(send_data));
            } else {
                ElMessage({
                    showClose: true,
                    message: '链接已断开，请刷新页面后重试',
                    type: 'error'
                });
            }
        },
        reconnectWebSocket() {
            if (!this.isConnected) {
                this.initWebSocket();
            }
        }
    },
    beforeUnmount() {
        if (this.websocket) {
            this.websocket.close();
        }
    }
};
</script>

<style>
.connected {
    color: green;
}

.disconnected {
    color: gray;
}

.price {
    width: 100%;
    height: 50%;
    margin: auto;
    margin-top: 10%;
}
</style>
