<template>
    <div class="notice">推特监控正在上架中，敬请期待。。。。</div>
</template>

<script>
export default {

}
</script>

<style>
.notice {
    font-family: 'KaiTi', '楷体';
    color: gray;
    /* 设置字体为楷体，对于英文字体，可以使用 sans-serif 作为后备 */
    font-weight: bold;
    /* 设置字体加粗 */
    font-size: 56px;
    /* 设置字号为 28px */
    text-align: center;
    /* 水平居中文本 */
    display: flex;
    /* 使用 Flexbox 布局 */
    justify-content: center;
    /* 水平居中子元素 */
    align-items: center;
    /* 垂直居中子元素 */
    height: 100%;
    /* 设置 div 的高度为 100%，以实现垂直居中 */
    margin: auto;
    /* 上下外边距自动调整，左右已通过 Flexbox 实现 */
    top: 0;
    /* 从顶部开始 */
    bottom: 0;
    /* 从底部开始 */
    left: 0;
    /* 从左侧开始 */
    right: 0;
    /* 从右侧开始 */
}
</style>