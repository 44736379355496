import { createStore } from 'vuex';

export default createStore({
    state: {
        // 状态
    },
    mutations: {
        // 变更状态的方法
        setShowMenu(state, visibility) {
            state.showMenu = visibility;
        },
    },
    actions: {
    // 异步操作
    },
    getters: {
    // 从状态派生的数据
    },
});