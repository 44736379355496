<template>
    <el-container class="btc-spot-container">
        <el-aside class="btc-spot-aside">
            <el-menu router :default-active="$route.path" class="el-menu-vertical-demo" background-color="#545c64"
                :collapse="isCollapsed" text-color="#fff" active-text-color="#ffd04b">
                <div :class="getToggleClass" @click="toggleSidebar">
                    <el-icon v-if="!isCollapsed" color="#fff" size="20">
                        <Fold />
                    </el-icon>
                    <el-icon v-if="isCollapsed" color="#fff" size="20">
                        <Expand />
                    </el-icon>
                </div>
                <el-menu-item index="/btc_spot_trader/settings">
                    <el-icon>
                        <Tools />
                    </el-icon>
                    <template #title>
                        <span>设置</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/btc_spot_trader/result">
                    <el-icon>
                        <Grid />
                    </el-icon>
                    <template #title>
                        <span>运行结果</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/btc_spot_trader/log">
                    <el-icon>
                        <List />
                    </el-icon>
                    <template #title>
                        <span>运行日志</span>
                    </template>
                </el-menu-item>
            </el-menu>
        </el-aside>
        <el-main class="btc-spot-main">
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component"></component>
                </keep-alive>
            </router-view></el-main>
    </el-container>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const isCollapsed = ref(false)
// const asideWidth = ref('200px')

const toggleSidebar = () => {
    isCollapsed.value = !isCollapsed.value
    const width = isCollapsed.value ? '64px' : '200px'
    document.documentElement.style.setProperty('--aside-width', width);
}

const getToggleClass = computed(() => {
    return isCollapsed.value ? 'toggle-collapse' : 'toggle-expand'
})

onMounted(() => {
    document.documentElement.style.setProperty('--aside-width', '200px');
})



</script>
<style scoped>
.btc-spot-container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.btc-spot-aside {
    width: var(--aside-width);
    height: 100%;
    transition: width 0.3s;
}

.btc-spot-main {
    flex-grow: 1 !important;
    padding: 0 !important;
    transition: margin-left 0.28s;
}
</style>
<style>
.el-menu-vertical-demo {
    height: 100%;
    width: var(--aside-width);
}

.toggle {
    width: var(--aside-width);
    text-align: right;

}

.toggle-collapse {
    padding-top: 5px;
    text-align: center;

}

.toggle-expand {
    width: 90%;
    padding-top: 5px;
    text-align: right;
}
</style>