<template>
    <div class="crypto-prices">
        <div class="crypto-container" @mouseenter="pauseAutoScroll" @mouseleave="resumeAutoScroll" @mousedown="startDrag">
            <div class="no-select crypto-grid" ref="cryptoGrid">
                <div v-for="(crypto, index) in this.cryptos" :key="crypto + index" class="crypto-card"
                    :class="getCryptoClass(displayCryptos.get(crypto)?.change)" @click="goToTradePage(crypto)">
                    <div class="crypto-info">
                        <!-- <img :src="`/src/assets/${crypto.symbol}.png`" :alt="crypto.symbol" class="crypto-icon" /> -->
                        <div>
                            <h3>{{ crypto }}</h3>
                            <p>${{ displayCryptos.get(crypto) ? displayCryptos.get(crypto).price : '' }}</p>
                            <!-- <p>{{ displayCryptos.get(crypto) ? displayCryptos.get(crypto).change : '' }}%</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CryptoPrices',
    data() {
        return {
            // cryptos: ["BTC", "ETH", "BNB", "SOL", "XRP", "DOGE", "ADA", "TRX", "AVAX", "SHIB", "WBTC", "DOT", "LINK", "BCH", "NEAR", "LTC", "MATIC", "UNI", "PEPE", "ICP", "ETC", "FET", "WBETH", "APT", "XLM", "STX", "HBAR", "ATOM"],
            cryptos: ["BTC", "ETH", "BNB", "SOL", "XRP", "DOGE", "ADA", "TRX", "AVAX", "SHIB", "WBTC", "DOT", "LINK", "BCH", "NEAR", "LTC",],
            displayCryptos: new Map(),
            currentIndex: 0,
            intervalId: null,
            startX: 0,
            scrollLeft: 0,
            isDragging: false,
            showBackToStart: false
        };
    },
    computed: {
        displayedCryptos() {
            return [...this.cryptos, ...this.cryptos.slice(0, 10)];
        },
        autoScrollEnabled() {
            return this.cryptos.length == 0 ? false : true
        },
        isElementWiderThanScreen() {
            const screenWidth = window.innerWidth;
            return this.$refs.cryptoGrid.offsetWidth > screenWidth;
        }
    },
    methods: {
        goToTradePage(symbol) {
            const url = `https://www.binance.com/zh-CN/trade/${symbol}_USDT`;
            window.open(url, '_blank');
        },
        autoScroll() {
            // if (this.isElementWiderThanScreen.value) {
            this.intervalId = setInterval(() => {
                this.nextCrypto();
            }, 2000);
            // }
        },
        getCryptoClass(change) {
            if (change && change.startsWith('+')) {
                return 'price-up'
            } else if (change && change.startsWith('-')) {
                return 'price-down'
            } else {
                return ''
            }
        },
        nextCrypto() {
            if (!this.autoScrollEnabled) {
                return
            }

            const width = this.$refs.cryptoGrid.firstElementChild.offsetWidth + 10; // Including margin
            this.currentIndex += 1;
            this.$refs.cryptoGrid.style.transition = 'transform 0.5s ease';
            this.$refs.cryptoGrid.style.transform = `translateX(-${width * this.currentIndex}px)`;

            if ((this.cryptos.length - this.currentIndex) * width < window.innerWidth) {
                this.$refs.cryptoGrid.style.transition = 'none';
                this.$refs.cryptoGrid.style.transform = `translateX(-${width * 0}px)`;
                this.currentIndex = 0;
                // setTimeout(() => {
                //     this.$refs.cryptoGrid.style.transition = 'none';
                //     this.$refs.cryptoGrid.style.transform = `translateX(-${width * 0}px)`;
                //     this.currentIndex = 0;
                // }, 500); // Match the transition duration
            }
        },
        pauseAutoScroll() {
            clearInterval(this.intervalId);
        },
        resumeAutoScroll() {
            if (this.autoScrollEnabled) {
                this.autoScroll();
            }
        },
        toggleAutoScroll() {
            this.autoScrollEnabled = !this.autoScrollEnabled;
            if (this.autoScrollEnabled) {
                this.autoScroll();
            } else {
                this.pauseAutoScroll();
            }
        },
        setupWebSocket() {
            this.ws = new WebSocket('wss://stream.binance.com:9443/ws');

            this.ws.onopen = () => {
                let sub_params = []
                this.cryptos.forEach(symbol => {
                    sub_params.push(symbol.toLowerCase() + 'usdt@ticker')
                })
                this.ws.send(
                    JSON.stringify({
                        method: 'SUBSCRIBE',
                        params: sub_params,
                        id: Date.now()
                    })
                )
            }

            this.ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                // let filteredData = data.filter(crypto => crypto.s.endsWith('USDT'))
                // filteredData = filteredData.filter(crypto => this.cryptos.includes(crypto.s.slice(0, -4)))
                // let filteredData = data.filter(crypto => parseFloat(crypto.q) > 10000000); 
                // filteredData = data.filter(crypto => crypto.s.endsWith('USDT'));
                // filteredData.sort((a, b) => (parseFloat(a.q) - parseFloat(b.q)))
                // this.cryptos = filteredData.map(crypto => ({
                //     symbol: crypto.s,
                //     price: crypto.c,
                //     change: crypto.P.startsWith('-') ? crypto.P : '+' + crypto.P
                // }));
                // this.updateDisplayedCryptos();
                if (data && data.e === '24hrTicker') {
                    const name = data.s.slice(0, -4)
                    const originPrice = this.displayCryptos.get(name)?.price
                    const info = {
                        price: Number(data.c),
                        change: Number(data.c) - Number(originPrice) > 0 ? '+' : '-'
                    }
                    this.displayCryptos.set(name, info)
                }
            };

            this.ws.onclose = () => {
                setTimeout(this.setupWebSocket, 1000);
            };
        },
        updateDisplayedCryptos() {
            this.displayedCryptos = [...this.cryptos, ...this.cryptos.slice(0, 10)];
        },
        // startDrag(e) {
        //     this.pauseAutoScroll();
        //     this.isDragging = true;
        //     this.startX = e.pageX - this.$refs.cryptoGrid.offsetLeft;
        //     this.scrollLeft = this.$refs.cryptoGrid.scrollLeft;
        //     document.addEventListener('mousemove', this.onDrag);
        //     document.addEventListener('mouseup', this.endDrag);
        // },
        // onDrag(e) {
        //     if (!this.isDragging) return;
        //     e.preventDefault();
        //     const x = e.pageX - this.$refs.cryptoGrid.offsetLeft;
        //     const walk = (x - this.startX) * 3; // Scroll-fast
        //     this.$refs.cryptoGrid.style.transform = `translateX(-${walk}px)`;
        //     // this.$refs.cryptoGrid.scrollLeft = this.scrollLeft - walk;
        //     this.updateBackToStartButton();
        // },
        // endDrag() {
        //     this.isDragging = false;
        //     this.resumeAutoScroll();
        //     document.removeEventListener('mousemove', this.onDrag);
        //     document.removeEventListener('mouseup', this.endDrag);
        // },
        // updateBackToStartButton() {
        //     const scrollLeft = this.$refs.cryptoGrid.scrollLeft;
        //     // const clientWidth = this.$refs.cryptoGrid.clientWidth;
        //     const clientWidth = window.innerWidth;
        //     console.log(this.showBackToStart)
        //     this.showBackToStart = scrollLeft > clientWidth;
        // },
        backToStart() {
            this.$refs.cryptoGrid.scrollLeft = 0;
            this.showBackToStart = false;
        }
    },
    watch: {
        displayCryptos: {
            deep: true
        }
    },
    mounted() {
        setTimeout(async () => {
            this.setupWebSocket();
            this.autoScroll();
        }, 0);
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    }
};
</script>

<style scoped>
.no-select {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE/Edge */
    user-select: none;
    /* 标准语法 */
}

.crypto-prices {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.crypto-container {
    overflow: hidden;
    width: 100%;
    cursor: grab;
}

.crypto-container:active {
    cursor: grabbing;
}

.crypto-grid {
    display: flex;
    justify-content: center;
    transition: transform 0.5s ease;
}

.crypto-card {
    flex: 0 0 auto;
    flex: 1;
    justify-content: flex-start;
    width: 200px;
    margin-right: 10px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
}

.crypto-info {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.crypto-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
}

.price-up {
    /* background-color: #e6f7e6; */
    background-color: #1e1e1e;
    color: green;
}

.price-down {
    /* background-color: #fce8e8; */
    background-color: #1e1e1e;
    color: red;
}

.toggle-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.back-to-start-button {
    position: absolute;
    right: 20px;
    top: 20px;
}
</style>
